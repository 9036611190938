import React, { useState } from "react";
import TopNav from "./TopNav";
import { HandleSubmit, CheckFormValidity } from "../../utility/Form";
import Toaster from "../Toaster/Toaster";
import { get, isArray, isEmpty } from "lodash";
import { navigate } from "@reach/router";

const GoToPage = (page) => {
  navigate(page)
}

const CommonAddItemComp = (props) => {
  // Toaster
  const [showToaster, setShowToaster] = useState(false);
  const [toasterData, setToasterData] = useState({});
  const setShowToasterFunc = (value) => {
    setShowToaster(value);
    setToasterData({});
  }
  // Toaster
  const { PuFormControlsAdd } = props,
    HandleSubmitFunc = async (event, additionalParams = {}) => {
      let submittedResult = {};
      if (additionalParams.isSubmit) {
        const Form = event.currentTarget;
        let formSubmit = {
          isSubmitted: true,
          hasError: additionalParams.enableValidation ? (Form.checkValidity() === false) : false
        };
        try {
          //Form validation CheckFormValidity
          if (formSubmit.hasError) {
            event.preventDefault()
            event.stopPropagation()
            formSubmit.error = CheckFormValidity(event, additionalParams);
          } else {
            submittedResult = await HandleSubmit(event, { ...props.additionalParams, ...additionalParams });
            if (!get(props, 'additionalParams.isAuth')) {
              setShowToaster(true);
              setToasterData({
                message: `${props.addBtnText ? props.addBtnText : 'Form'} saved successfully`,
                heading: props.menu,
                variant: 'primary'
              })
              if (!isEmpty(submittedResult.data) && (!additionalParams.isPageRedirect)) {
                GoToPage(`/${props.parentPath}/edit/${submittedResult?.data?.id}/`);
              }
              if (additionalParams.isPageRedirect && additionalParams.pageRedirectUrl) {
                GoToPage(additionalParams.pageRedirectUrl);
              }
            }
          }
          return Promise.resolve({ formSubmit, submittedResult });
        } catch (error) {
          const ErrMsg = get(error, 'response.data.message');
          let messageNew = ErrMsg;
          if (isArray(ErrMsg)) {
            messageNew = get(error, 'response.data.message[0].messages[0].message')
          }
          setShowToaster(true);
          setToasterData({
            message: messageNew || 'Server error!',
            heading: props.menu,
            variant: 'danger'
          })
          return Promise.reject(error);
        }
      } else {
        try {
          submittedResult = await HandleSubmit(event, { ...props.additionalParams, ...additionalParams });
          if (!isEmpty(submittedResult.data) && (!additionalParams.isPageRedirect)) {
            GoToPage(`/${props.parentPath}/edit/${submittedResult?.data?.id}/`);
          }
          if (additionalParams.isPageRedirect && additionalParams.pageRedirectUrl) {
            GoToPage(additionalParams.pageRedirectUrl);
          }
          return Promise.resolve({ ...{}, submittedResult });
        } catch (error) {
          const ErrMsg = get(error, 'response.data.message');
          let messageNew = ErrMsg;
          if (isArray(ErrMsg)) {
            messageNew = get(error, 'response.data.message[0].messages[0].message')
          }
          setShowToaster(true);
          setToasterData({
            message: messageNew || 'Server error!',
            heading: props.menu,
            variant: 'danger'
          })
          return Promise.reject(error);
        }
      }
    }
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          {
            props.SkipDefaultHeader ? (
              ""
            ) : (
              <TopNav
                menu={props.menu}
                parentPath={props.parentPath}
                pageType={props.pageType}
              />
            )
          }
          {PuFormControlsAdd(HandleSubmitFunc)}
        </div>
      </div>
      {
        showToaster && <Toaster
          handleClick={showToaster}
          setShowToast={(val) => setShowToasterFunc(val)}
          variant={toasterData.variant}
          heading={toasterData.heading}
          message={toasterData.message}
        />
      }
    </>
  )
}

export default CommonAddItemComp;