import React, { useState } from "react";
import { Row, Col, Button, Tabs, Tab, Form, Alert } from 'react-bootstrap'
import {
  HandleChange, HandleAttachmentField, CheckFieldValidity, CheckFormValidity,
  GenerateRandomString, DefaultSelectValue, ClearAttachmentField
} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import { set, get, isEmpty, find } from "lodash";
import Select from 'react-select';
import ConfirmOnExitComp from "../common/ConfirmOnExit";
import { isBrowser } from "../common/ggfx-client/utility";

const AddFormControls = (props) => {
  //Set agent role 
  //TODO: Get the role from ENV
  const [DefaultFields, SetDefaultFields] = useState({
    password: GenerateRandomString(10),
    is_agent: true
  });
  const ContactTypes = [DefaultSelectValue, { value: 'Sales', label: 'Sales' }, { value: 'Lettings', label: 'Lettings' },
    { value: 'Sales & Lettings', label: 'Sales & Lettings' }, { value: 'Relocation Agents', label: 'Relocation Agents' },
    { value: 'Other', label: 'Other' }];
  const [checkboxValues, setCheckboxValues] = useState({});
  const [pictureUrl, setPictureUrl] = useState("");
  const GetUploadedUrl = async (event) => {
    try {
      const UploadedResult = await HandleAttachmentField(event, { ...props });
      set(DefaultFields, `${UploadedResult['field'] || 'media'}`, { url: UploadedResult.url, id: UploadedResult.id })
      setPictureUrl(UploadedResult.url);
    } catch (error) {
      console.log('CommonAddItemComp GetUploadedUrl error', error, event, props)
    }
  }

  const [formError, setFormError] = useState({});
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);
  const CheckFormFieldValidity = (event) => {
    const FieldError = CheckFieldValidity(event);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    CheckFormFieldValidity(event);
    if (event.target.name === 'email') {
      set(DefaultFields, 'username', event.target.value);
    }
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      setIsFormSaveTriggered(true);
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'add-agent-form',
        ...additionalParams,
        fields: {
          ...DefaultFields
        }
      })
      if (get(Result, 'formSubmit.hasError')) {
        setFormError(Result.formSubmit.error);
      } else if (Result.submittedResult) {
        setIsFormSaveTriggered(false);
        setTabKey("0");
        setContactType(DefaultSelectValue);
        setPictureUrl("")
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const [tabKey, setTabKey] = useState(0);
  const MoveToTab = async (event, key, additionalParams = {}) => {
    setIsFormSaveTriggered(true);
    const ChildNodes = document.getElementsByClassName('tab-pane active')[0];
    const TabError = CheckFormValidity(ChildNodes.getElementsByClassName('form-control'),
      { isSubmit: false, isCustom: true });
    //Check any field has error
    let tabHasError = false;
    const TabErrorKeys = Object.keys(TabError),
      TabErrorKeysLength = TabErrorKeys.length;
    for (let index = 0; index < TabErrorKeysLength; index++) {
      tabHasError = TabError[TabErrorKeys[index]]?.hasError;
      if (tabHasError) {
        setFormError(TabError);
        break;
      }
    }
    let selectBoxErrorObjTemp = {};
    if ((!tabHasError) && (isEmpty(find(selectBoxErrorObjTemp, function (serror) {
      return serror.hasError;
    })))) {
      if (additionalParams.isSubmit) {
        try {
          await FormSubmitFunc(event, additionalParams);
          setTabKey(key);
          setIsFormSaveTriggered(false);
        } catch (error) {
          console.log('additionalParams.isSave', error);
        }
      } else {
        setTabKey(key);
        setIsFormSaveTriggered(false);
      }
    }
    MoveToTopOfPage();
  };

  const SaveCurrentTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    MoveToTab(event, (parseInt(tabKey) + 1), {
      isSubmit: true,
      enableValidation: (tabKey === '1')
    });
  }

  const MoveToTopOfPage = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
    }
  }

  const HandleCheckBoxValue = (event) => {
    setCheckboxValues({ ...checkboxValues, ...{ [event.target.name]: event.target.checked } });
  }

  const [contactType, setContactType] = useState(DefaultSelectValue);
  const HandleSelect = (event) => {
    setContactType(event);
  }

  return (
    <>
      <Form id="add-agent-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        <section className="agent-tabs">
          {
            isFormSaveTriggered && (find(formError, function (error) {
              return (error.hasError)
            })) && <Alert key="danger" variant="danger">
              Highlighted fields are required
            </Alert>
          }
          <Tabs
            defaultActiveKey={"0"}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            activeKey={tabKey}
            onSelect={(k) => MoveToTab({}, k)}
          >
            <Tab eventKey={"0"} title="Agent Contact Details">
              <input type="hidden" name="source" value="property-uploader" />
              <input type="hidden" name="approval_status" value="approved" />
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Contact Name *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError["nego_name"]?.hasError ? ' pu-has-control-error' : ''}`}
                      placeholder="Enter contact name here"
                      onChange={(event) => HandleChangeFunc(event)}
                      name="nego_name" required={true} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Email Address *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.email?.hasError ? ' pu-has-control-error' : ''}`}
                      placeholder="Enter email address here"
                      name="email" required={true}
                      onChange={(event) => HandleChangeFunc(event)}
                      // Every \ should comes with \\ due to string to regexp conversion
                      pattern={"\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Job Title </label>
                    <input type="text" className={`form-control`}
                      placeholder="Enter job title here"
                      onChange={(event) => HandleChangeFunc(event)}
                      name="contact_info.job_title" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Contact Types</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="contact_info.agent_type"
                        options={ContactTypes}
                        placeholder="Please select contact type"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(event) => HandleSelect(event)}
                        value={contactType}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Telephone</label>
                    <input type="text" className="form-control" placeholder="Enter telephone here"
                      name="contact_info.telephone" onChange={(event => HandleChange(event))}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Mobile Number</label>
                    <input type="text" className="form-control" placeholder="Enter mobile number here"
                      name="contact_info.mobile_no" onChange={(event => HandleChange(event))}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"1"} title="Branch Details">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Agency Name *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError["agency_name"]?.hasError ? ' pu-has-control-error' : ''}`}
                      placeholder="Enter agency name here"
                      onChange={(event) => HandleChangeFunc(event)}
                      name="agency_name" required={true} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Branch Name *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError["branch_name"]?.hasError ? ' pu-has-control-error' : ''}`}
                      placeholder="Enter branch name here"
                      name="branch_name" required={true}
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 1</label>
                    <input type="text" className="form-control" placeholder="Enter address here"
                      name="branch_info.address1" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 2</label>
                    <input type="text" className="form-control" placeholder="Enter address here"
                      name="branch_info.address2" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Town/Area</label>
                    <input type="text" className="form-control" placeholder="Enter town/area here"
                      name="branch_info.branch_area" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>City/County</label>
                    <input type="text" className="form-control" placeholder="Enter city/county here"
                      name="branch_info.branch_city" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Postcode</label>
                    <input type="text" className="form-control" placeholder="Enter postcode here"
                      name="branch_info.branch_postcode" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Telephone</label>
                    <input type="text" className="form-control" placeholder="Enter telephone here"
                      name="branch_info.branch_telephone"
                      onChange={(event => HandleChange(event))}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>E-Mail address</label>
                    <input type="text" className="form-control" placeholder="Enter E-Mail address here"
                      name="branch_info.branch_email" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Website URL</label>
                    <input type="text" className="form-control" placeholder="Enter website url here"
                      name="branch_info.branch_website" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Description</label>
                    <input type="text" className="form-control" placeholder="Enter description here"
                      name="branch_info.description" />
                  </div>
                </Col>
                <Col lg={5} className="mb-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="form-wrapper w-100">
                      <label>Picture</label>
                      {/* NOTE: Every input file field shoud have name field it mailyb used for upload the file in specific path */}
                      <input type="file" name="branch_info.picture" className="form-control file-attach"
                        onChange={(event) => GetUploadedUrl(event, 'custom-file1')} id="custom-file1"
                        placeholder="Click to attach" />
                    </div>
                    {
                      pictureUrl &&
                      <div className="preview-img w-100 mt-3 mb-3">
                        <a href={pictureUrl} target="_blank">
                          <img src={pictureUrl} className="img-fluid" style={{ width: "100px", height: "70px", objectFit: "cover" }} />
                        </a>
                      </div>
                    }
                  </div>
                </Col>
                <Col lg={1} className="mb-4">
                  <label> </label>
                  <Button className="btn btn-default" onClick={() => {
                    ClearAttachmentField('custom-file1');
                    setPictureUrl('');
                  }}>Clear</Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <ul className="list-inline m-0">
                    <li className="list-inline-item m-0 me-5">
                      <div className="form-check dashboard-checkbox">
                        <input className="form-check-input" type="checkbox"
                          value={true}
                          id="showonsite"
                          name="branch_info.is_show_on_site"
                          checked={checkboxValues?.['branch_info.is_show_on_site']}
                          onChange={event => HandleCheckBoxValue(event)} />
                        <label className="form-check-label">Show on site</label>
                      </div>
                    </li>
                    <li className="list-inline-item m-0">
                      <div className="form-check dashboard-checkbox">
                        <input className="form-check-input" type="checkbox"
                          value={true}
                          id="showwebsiteurl"
                          name="branch_info.is_show_website_url"
                          checked={checkboxValues?.['branch_info.is_show_website_url']}
                          onChange={event => HandleCheckBoxValue(event)} />
                        <label className="form-check-label" >Show Website URL</label>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg={6} className="mb-4">
                  <ul className="list-inline m-0">
                    <li className="list-inline-item m-0 me-5">
                      <div className="form-check dashboard-checkbox">
                        <input className="form-check-input" type="checkbox"
                          value={true}
                          id="receivelettingslist"
                          name="branch_info.is_receive_letting_list"
                          checked={checkboxValues?.['branch_info.is_receive_letting_list']}
                          onChange={event => HandleCheckBoxValue(event)} />
                        <label className="form-check-label">Receive Lettings List</label>
                      </div>
                    </li>
                    <li className="list-inline-item m-0">
                      <div className="form-check dashboard-checkbox">
                        <input className="form-check-input" type="checkbox"
                          value={true}
                          id="reveicedisposalslist"
                          name="branch_info.is_receive_disposal_list"
                          checked={checkboxValues?.['branch_info.is_receive_disposal_list']}
                          onChange={event => HandleCheckBoxValue(event)} />
                        <label className="form-check-label">Receive Disposals List</label>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "0")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Submit</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </section>
      </Form>
    </>
  )
}

const AddAgent = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls  {...props} HandleSubmitFunc={HandleSubmitFunc} />
        </>
      )

    }
  } {...props} DisableDefaultFormAction={true} />
}

export default AddAgent;