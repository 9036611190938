import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import AgentAdd from "@components/agents/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "agents.create"
}

const AgentAddPage = (props) => {
  return (
  <>
    <Seo title="Add Agent" />
      <div className="main-wrapper">
        <Sidebar menuActive="agents" 
          action="Add"/>
        <AgentAdd 
          menu="Add Agents" 
          parentPath="agents" 
          pageType="Add"
          additionalParams={AdditionalParams}/>
      </div>
    </>
  )
}

export default AgentAddPage;
